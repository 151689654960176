import { Chain } from 'wagmi'

export const Localhost = {
    id: 8_545,
    name: 'Localhost',
    network: 'localhost',
    nativeCurrency: {
        decimals: 18,
        name: 'BNB',
        symbol: 'BNB',
    },
    rpcUrls: {
        public: { http: ['http://192.168.3.100:8545'] },
        default: { http: ['http://192.168.3.100:8545'] },
    },
    blockExplorers: {
        etherscan: { name: 'SnowTrace', url: 'http://transfer.qytech.org' },
        default: { name: 'SnowTrace', url: 'http://transfer.qytech.org' },
    }
} as const satisfies Chain