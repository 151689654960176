import { Button, Col, Descriptions, Input, Modal, Row } from "antd";
import { dateTimeWays, messageWay } from "src/utils/pubilcUtils";
import { useEffect, useState } from "react";
import "./binding.scss";
import { useTranslation } from "react-i18next";
import useMyInfo from "../hooks/useMyInfo";
import { useChainId, useSendWeb3 } from "src/hooks/useWeb3";
import { useAddress } from "src/hooks/useAddress";
import GalaxyFamily from "src/abis/GalaxyFamily.json";
const Level = (props) => {
  const address = useAddress();
  const sendWeb = useSendWeb3();
  const chainId = useChainId();
  const { t, i18n } = useTranslation();
  const { onClose, isSindModule } = props;
  const {
    setLoading,
    isAddress,
    setInputParentAddress,
    inputParentAddress,
    infoLoading,
    makeRelation,
    parentOfMethod,
    rootAddress,
    setIsBind,
  } = useMyInfo();

  const cancel = () => {
    onClose();
  };
  useEffect(() => {
    const urlset = () => {
      let hash = location.hash;
      let value = hash.split("=")[1];
      if (value != undefined) {
        setInputParentAddress(value);
      }
    };
    urlset();
  }, []);
  const bind = async () => {
    const GalaxyFamily_contract = new sendWeb.eth.Contract(
      GalaxyFamily.abi,
      GalaxyFamily.address[chainId]
    );
    const isAddress_ = await isAddress(inputParentAddress);
    if (inputParentAddress == "") {
      return messageWay(t("home.tips.text1"), "error");
    } else if (inputParentAddress == address) {
      return messageWay(t("home.tips.text1"), "error");
    } else if (!isAddress_) {
      return messageWay(t("home.tips.text1"), "error");
    }
    const parentOf = await parentOfMethod(inputParentAddress);
    if (inputParentAddress.toString() === rootAddress.toString()) {
      return makeRelation();
    }
    if (
      parentOf &&
      parentOf.toString() === "0x0000000000000000000000000000000000000000"
    ) {
      return messageWay(t("home.tips.text1"), "error");
    }
    setLoading(true);
    try {
      await GalaxyFamily_contract.methods
        .makeRelation(inputParentAddress)
        .send({
          from: address,
        });
      messageWay(t("home.tips.text2"), "success");
      onClose();
    } catch (err) {
      console.log(err);
      onClose();
    } finally {
      setLoading(false);
    }
  };
  const changeInput = (e) => {
    setInputParentAddress(e.target.value);
  };
  return (
    <Modal
      className="binding"
      open={isSindModule}
      footer={null}
      closable={true}
      onCancel={cancel}
      title={t("home.tips.text5")}
    >
      <div className="box">
        <Input
          onInput={changeInput}
          value={inputParentAddress}
          placeholder={t("home.tips.text6")}
        ></Input>
        <Button className="same-btn" loading={infoLoading} onClick={bind}>
          {t("home.btn3")}
        </Button>
      </div>
    </Modal>
  );
};

export default Level;

// function useFamilyData(): { pendingLevelOf: any; } {
//     throw new Error("Function not implemented.");
// }
