import { Select } from "antd";
import { useTranslation } from "react-i18next";
import './index.scss';
import { isMobile } from '../../utils/pubilcUtils';
import pic4 from "../../assets/images/pic4.png";

const Page = () => {
    const { t, i18n } = useTranslation()
    const ism = isMobile();
    const changeLanguages = (e: any) => {
        i18n.changeLanguage(e);
        localStorage.setItem('lang', e);
    }
    return (<div className="languages">
        {
            !ism ? '' :
                <div className="imgaddtext">
                    <img src={pic4} alt="" />
                    <span>语言</span>
                </div>

        }
        <Select className="language"
            defaultValue={
                localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh_CN'
            }
            onChange={changeLanguages}
        >
            <Select.Option value="zh_CN"> {t('chinese')}</Select.Option>
            <Select.Option value="en">{t('english')}</Select.Option>
            {/* <Select.Option value="ja">{t('ja')}</Select.Option>
                    <Select.Option value="ko">{t('ko')}</Select.Option>
                    <Select.Option value="ru">{t('ru')}</Select.Option> */}
        </Select>
    </div>)
}

export default Page;