import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-liquidfill';
import { useIsPC } from '../../../utils/pubilcUtils';
import useHomeData from './useHomeData';
import { formatEther, formatUnits } from '@ethersproject/units';
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
// const EchartsLiquidfill: React.FC = () => {
const EchartsLiquidfill = (props: any) => {
    const rewardPendings = props
    const IsPC = useIsPC();
    const { rewardTotal, startOf, rewardPending } = useHomeData();

    useEffect(() => {
        const value = parseFloat(String(formatUnits(String(rewardPendings.rewardPendings), BigNumber.from(8)))).toFixed(2);
        let percent = 0.5;
        // if (Number(startOf) == 1) {
        //     percent = parseFloat(value) / 3000;
        //     console.log()
        // } else {
        //     percent = 0.5;
        // }
        const chart = echarts.init(document.getElementById('liquidfill-chart')!);
        const font_Size = IsPC ? 38 : 14;//字体大小
        const option = {
            series: [{
                type: 'liquidFill',
                radius: '100%',
                with: '206px',
                data: [{
                    value: value,
                    direction: 'left',
                    itemStyle: {
                        normal: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    { offset: 0, color: '#238F7A' },
                                    { offset: 1, color: 'rgba(0, 0, 0, 1)' }
                                ],
                                globalCoord: false
                            },
                            borderColor: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    { offset: 0, color: '#FFDC00' },
                                    { offset: 1, color: '#AA05C4' }
                                ],
                                globalCoord: false
                            },
                            borderWidth: 10 // 设置边框宽度
                        }
                    }
                }, {
                    value: percent,
                    direction: 'right',
                    itemStyle: {
                        normal: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    { offset: 0, color: 'rgba(255, 220, 0, 1)' },
                                    { offset: 1, color: 'rgba(170, 5, 196, 1)' }
                                ],
                                globalCoord: false
                            }
                        }
                    }
                }],
                backgroundStyle: {
                    type: 'rgba',
                    color: [0, 0, 0, 1]
                },
                center: ['50%', '50%'],
                label: {
                    show: true,
                    position: 'inside',
                    formatter: function (params: any) {
                        return params.value.toString();
                    },
                    fontSize: font_Size
                },
                outline: {
                    show: false
                }
            }],

        };
        chart.setOption(option);
        return () => {
            chart.dispose();
        };
    }, [rewardPending, rewardPendings]);


    return <div id="liquidfill-chart" ></div>;
};

export default EchartsLiquidfill;