import { Button, Col, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import "./trends.scss";
import { useTranslation } from "react-i18next";
const trends = (props) => {
  const { onClose, visibleUpgrade } = props;
  const { t, i18n } = useTranslation();
  const cancel = () => {
    onClose();
  };

  return (
    <Modal
      className="claimRecords"
      open={true}
      footer={null}
      closable={true}
      onCancel={cancel}
      title={t("menu.News")}
    >
      <div className="box">
        <span className="title">{t("trends.text")}</span>
        <span>{t("trends.text1")}</span>
        <div className="GalaxyPlan">
          <span>{t("trends.text2")}</span>
          <span>2023.7.12</span>
        </div>
      </div>
    </Modal>
  );
};

export default trends;
