import { Button, Col, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import "./level.scss";
import useHomeData from "../hooks/useHomeData";
import { formatEther } from "@ethersproject/units";
import { useChainId, useSendWeb3 } from "src/hooks/useWeb3";
import { dateTimeWays, messageWay } from "src/utils/pubilcUtils";
import { useAddress } from "src/hooks/useAddress";
import LevelsContract from "src/abis/GalaxyLevels.json";
import { useTranslation } from "react-i18next";
const Level = (props) => {
  const { onClose, visibleUpgrade } = props;
  const { t, i18n } = useTranslation();
  const { pendingLevelOf, levelUpgrade } = useHomeData();
  const sendWeb = useSendWeb3();
  const chainId = useChainId();
  const address = useAddress();
  const [claimLoading, setclaimLoading] = useState(false);
  const cancel = () => {
    onClose();
  };
  const setUpgrade = async () => {
    // levelUpgrade();
    setclaimLoading(true);
    const levels_contract = new sendWeb.eth.Contract(
      LevelsContract.abi,
      LevelsContract.address[chainId]
    );
    try {
      await levels_contract.methods.levelUpgrade().send({
        from: address,
      });
      messageWay(t("Level.text1"), "success");
      onClose();
      setclaimLoading(false);
    } catch (error) {
      onClose();
      // a(true);
      setclaimLoading(false);
      messageWay(t("CANCEL"), "error");
    }
  };
  return (
    <Modal
      className="claimRecords"
      open={visibleUpgrade}
      footer={null}
      closable={true}
      onCancel={cancel}
      title={t("Level.text2")}
    >
      <div className="box">
        <div className="rule">
          {t("Level.text3")}
          {Number(pendingLevelOf)}！
        </div>
        <Button
          className="same-btn"
          loading={claimLoading}
          onClick={setUpgrade}
        >
          {t("home.btn3")}
        </Button>
      </div>
    </Modal>
  );
};

export default Level;

// function useFamilyData(): { pendingLevelOf: any; } {
//     throw new Error("Function not implemented.");
// }
