import { useAddress } from "src/hooks/useAddress";
import HomeContract from "src/abis/GalaxyHome.json";
import LevelsContract from "src/abis/GalaxyLevels.json";
import UsdtContract from "src/abis/USDToken.json";
import { useChainId, useSendWeb3 } from "src/hooks/useWeb3";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { BigNumber } from "@ethersproject/bignumber";
import { messageWay } from "src/utils/pubilcUtils";
import { formatEther } from "@ethersproject/units";
import { useTranslation } from "react-i18next";
import { Await } from "react-router-dom";
export default function useHomeData() {
  const address = useAddress();
  const sendWeb = useSendWeb3();
  const { t, i18n } = useTranslation();
  const chainId = useChainId();
  const [startOf, setStartOf] = useState(BigNumber.from(0));
  const [upgradeAmounts, setUpgradeAmounts] = useState(BigNumber.from(0)); //升级所需
  const [balance, setBalance] = useState(BigNumber.from(0));

  const [loading, setLoading] = useState(false);
  const [Info, setUseInfo] = useState([]);

  const [rewardQuota, setRewardQuota] = useState(BigNumber.from(0));
  const [rewardPending, setrewardPending] = useState(BigNumber.from(0));
  const [rewardTotal, setrewardTotal] = useState(BigNumber.from(0));

  const [myPower, setMyPower] = useState(BigNumber.from(0));
  const [claimLoading, setClaimLoading] = useState(false);
  const [clickable, setclickable] = useState(false);
  const [visibleUpgrade, setVisibleUpgrade] = useState(false);
  const [isNotYet, setisNotYet] = useState(false);
  const [pendingLevelOf, setpendingLevelOf] = useState();

  useEffect(() => {
    getInfo();
  }, [sendWeb, address, startOf]);

  const RefresrewardPending = async () => {
    if (address) {
      const home_contract = new sendWeb.eth.Contract(
        HomeContract.abi,
        HomeContract.address[chainId]
      );
      const earnedTotal = await home_contract.methods
        .earnedTotal(address)
        .call();
      setrewardPending(earnedTotal);
    }
  };

  const getInfo = async () => {
    if (address) {
      if (LevelsContract.address[chainId] == undefined) return;
      const levels_contract = new sendWeb.eth.Contract(
        LevelsContract.abi,
        LevelsContract.address[chainId]
      );
      const home_contract = new sendWeb.eth.Contract(
        HomeContract.abi,
        HomeContract.address[chainId]
      );
      const usdt_contract = new sendWeb.eth.Contract(
        UsdtContract.abi,
        UsdtContract.address[chainId]
      );
      //星级

      let resultstartOf = await levels_contract.methods.startOf(address).call();
      setStartOf(resultstartOf);
      // console.log("resultstartOf" + resultstartOf);
      let num = Number(resultstartOf) + 1;
      if (Number(resultstartOf) < 6) {
        let upgradeAmounts_result = await home_contract.methods
          .upgradeAmounts(num)
          .call();
        setUpgradeAmounts(upgradeAmounts_result);
      }
      let balanceOf = await usdt_contract.methods.balanceOf(address).call();
      setBalance(balanceOf);

      const useInfo_result = await home_contract.methods
        .userInfoOf(address)
        .call();
      const earnedTotal = await home_contract.methods
        .earnedTotal(address)
        .call();
      setrewardPending(earnedTotal);

      // setclickable(false);
      setRewardQuota(useInfo_result.rewardQuota);
      setrewardTotal(useInfo_result.rewardTotal);
      // setUseInfo(data);
      RefreshLevel();
      // const NotYet = await ISparticipatein();
      // setisNotYet(NotYet);
    }
  };

  const ISparticipatein = async () => {
    const home_contract = new sendWeb.eth.Contract(
      HomeContract.abi,
      HomeContract.address[chainId]
    );
    try {
      const upgrade = await home_contract.methods.upgrade().call();
      // console.log(upgrade);
    } catch (err) {
      if (err.data.message.includes("execution reverted: NotYet")) {
        return true;
      } else {
        return false;
      }
    }
  };

  const RefreshLevel = async () => {
    if (address) {
      const levels_contract = new sendWeb.eth.Contract(
        LevelsContract.abi,
        LevelsContract.address[chainId]
      );
      let pendingLevelOf = await levels_contract.methods
        .pendingLevelOf(address)
        .call();
      let result = pendingLevelOf;
      setpendingLevelOf(pendingLevelOf[1]);
      if (pendingLevelOf) {
        const origin = Number(pendingLevelOf[0]);
        const current = Number(pendingLevelOf[1]);
        if (current > origin) {
          setVisibleUpgrade(true);
        } else {
          setVisibleUpgrade(false);
        }
      }
    }
  };
  //等级

  const Clicktoquery = async (num) => {
    const home_contract = new sendWeb.eth.Contract(
      HomeContract.abi,
      HomeContract.address[chainId]
    );
    let upgradeAmounts_result = await home_contract.methods
      .upgradeAmounts(num)
      .call();

    home_contract.methods
      .upgradeAmounts(num)
      .call()
      .then((res, err) => {
        if (!err) {
          setUpgradeAmounts(res);
        }
      });
  };

  const submit = async () => {
    if (BigNumber.from(upgradeAmounts).eq(BigNumber.from(0))) return;
    const usdt_contract = new sendWeb.eth.Contract(
      UsdtContract.abi,
      UsdtContract.address[chainId]
    );
    let allowance = await usdt_contract.methods
      .allowance(address, HomeContract.address[chainId])
      .call();
    if (upgradeAmounts > balance) return messageWay(t("home.text18"), "error");
    setLoading(true);
    if (allowance >= balance) {
      upgrade();
    } else {
      try {
        await usdt_contract.methods
          .approve(HomeContract.address[chainId], "10000000000000000000000")
          .send({
            from: address,
          });
        messageWay(t("tips.approveS"), "success");
        upgrade();
      } catch (error) {
        messageWay(t("CANCEL"), "error");
        setLoading(false);
      }
    }
  };
  const upgrade = async () => {
    const home_contract = new sendWeb.eth.Contract(
      HomeContract.abi,
      HomeContract.address[chainId]
    );

    try {
      await home_contract.methods.upgrade().send({
        from: address,
      });
      messageWay(t("home.tips.text3"), "success");
      setLoading(false);
      getInfo();
    } catch (error) {
      messageWay(t("CANCEL"), "error");
      setLoading(false);
    }
  };

  const levelUpgrade = async () => {
    // setClaimLoading(true);
    // const levels_contract = new sendWeb.eth.Contract(
    //   LevelsContract.abi,
    //   LevelsContract.address[chainId]
    // );
    // try {
    //   await levels_contract.methods.levelUpgrade().send({
    //     from: address,
    //   });
    //   messageWay("升级成功", "success");
    //   setVisibleUpgrade(false);
    //   setClaimLoading(false);
    //   getInfo();
    // } catch (error) {
    //   setVisibleUpgrade(false);
    //   messageWay("取消", "error");
    //   setClaimLoading(false);
    // }
  };

  const claim = async () => {
    setClaimLoading(true);
    const home_contract = new sendWeb.eth.Contract(
      HomeContract.abi,
      HomeContract.address[chainId]
    );
    // home_contract.methods.takeReward().call().then(console.log);
    try {
      await home_contract.methods.takeReward().send({
        from: address,
      });
      messageWay(t("home.tips.text4"), "success");
      RefresrewardPending();
    } catch (err) {
      console.log(err);
      messageWay(t("CANCEL"), "error");
    } finally {
      setClaimLoading(false);
    }
  };
  const [recordsList, setRecordsList] = useState([]);

  const getLists = async () => {
    try {
      const response = await fetch(
        "https://api.thegraph.com/subgraphs/name/galaxyplanproject/galaxy_graph",
        // "http://192.168.3.100:8000/subgraphs/name/galaxy/v2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
         query {
            rewardIncreaseds(where:{owner:"${address}"}) {
                amount
                time
                rewardType
            }
        }
    `,
          }),
        }
      );
      const result = await response.json();
      const arr = result.data.rewardIncreaseds;
      let list = [];
      for (let i = 0; i < arr.length; i++) {
        list.push({
          rewardType: arr[i].rewardType,
          amount: arr[i].amount,
          time: arr[i].time,
        });
      }
      list.sort((a, b) => b.time - a.time);
      setRecordsList(list);
    } catch (error) {}
  };
  useEffect(() => {
    getLists();
  }, [address]);

  return {
    address,
    startOf,
    upgradeAmounts,
    balance,
    loading,
    submit,
    rewardQuota,
    rewardPending,
    rewardTotal,
    claimLoading,
    claim,
    recordsList,
    Clicktoquery,
    setclickable,
    clickable,
    visibleUpgrade,
    setVisibleUpgrade,
    pendingLevelOf,
    levelUpgrade,
    setLoading,
    RefreshLevel,
    getInfo,
    isNotYet,
  };
}
