import React, { useEffect, useState } from "react"
import Web3 from "web3";

interface ContextProps {
    sendWeb3: Web3 | undefined;
    address: string | undefined;
    chainId: Number | undefined;
}
export const CoreDateContext = React.createContext<ContextProps>({
    sendWeb3: undefined,
    address: undefined,
    chainId: undefined
})

interface WindowChain extends Window {
    ethereum?: any
}

export const CoreDateProvider = (props: any) => {

    const [web3, setWeb3] = useState<Web3>();
    const [chainId, setChainId] = useState<Number>();
    const [selectAddress, setSelectAddress] = useState<string>();

    useEffect(() => {
        if ((window as WindowChain).ethereum) {
            setConnectProvider((window as WindowChain).ethereum);
        }

    }, [])

    const setConnectProvider = async (provider: any) => {
        let newWeb3 = new Web3(provider)
        setWeb3(newWeb3);

        let chainId = Number(await newWeb3.eth.getChainId());
        setChainId(chainId);
        await newWeb3.eth.getAccounts().then(res => {
            // console.log(res);
            setSelectAddress(res[0]);
        });

        provider.on('accountsChanged', (accounts: any) => {
            setSelectAddress(accounts[0]);
        });
    }


    return <CoreDateContext.Provider
        value={{
            sendWeb3: web3,
            chainId: chainId,
            address: selectAddress
        }}
    >{props.children}</CoreDateContext.Provider>


}
