import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { createPublicClient, http } from 'viem'
import { mainnet } from 'viem/chains'
import { bsc } from 'wagmi/chains'
import { Localhost } from 'src/chains'
import reportWebVitals from './reportWebVitals';
import './i18n';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const { chains, publicClient } = configureChains(
  [Localhost, bsc],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        let url: string
        if (chain.name === 'Localhost') {
          url = chain.rpcUrls.default.http[0]
        } else {
          url = 'https://virulent-polished-aura.bsc.quiknode.pro/a1d19a699da0054609a9b69aca558251b39e3fdf'
        }
        return { http: url }
      },
    })
  ],
);
// const { connectors } = getDefaultWallets({
//   appName: 'UU',
//   chains,
//   projectId: 'UU'
// });

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: mainnet,
    transport: http()
  }),
})

root.render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <App />
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
