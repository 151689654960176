import { useMemo, useContext, useEffect } from "react";
import { CoreDateContext } from "src/providers/coreDateProvider";


export const useSendWeb3 = () => {
    const { sendWeb3 } = useContext(CoreDateContext);
    
  const web= useMemo(() => {
      return sendWeb3;
    }, [sendWeb3]);
  
    // 在此处执行其他逻辑或返回其他内容
    return web;

  };

// export const useReadWeb3 = (name) => {
//     const { readWeb3s } = useContext(CoreDataContext)
//      useMemo(() => {
//         return readWeb3s[name]
//     }, [readWeb3s])
// }

// export const useWeb3 = (name) => {
//     const { sendWeb3, readWeb3s } = useContext(CoreDataContext)
//     if (name == undefined) {
//          useMemo(() => {
//             return sendWeb3
//         }, [sendWeb3])
//     } else {
//          useMemo(() => {
//             return readWeb3s[name]
//         }, [readWeb3s])
//     }
// }

export const useChainId=()=>{
    const {chainId}=useContext(CoreDateContext);
     
    const web= useMemo(() => {
      return chainId;
    }, [chainId]);

    return web;
}