
import { isMobile } from '../../utils/pubilcUtils';
import './index.scss';
import { useTranslation } from 'react-i18next';
const Page = () => {
    const { t } = useTranslation();

    const ism = isMobile();

    return (
        <div className="footer">
            <div className='container'>
                <div className='block1'>
                    <div className='left'>
                        {
                            !ism ? <img src={require('src/assets/logo.png')} alt='logo'></img>
                                : ''
                        }
                        <div className='content'>
                            <div className=''>{t('footer.text1')}： </div>
                            <div>{t('footer.text2')}</div>
                        </div>
                    </div>
                    {
                        !ism ?
                            <div className='right'>
                                <a href=''  >{t('menu.about')}     ｜    </a>
                                <a href='' >{t('menu.News')}    ｜     </a>
                                <a href='https://galaxyplan.org/AuditReport.pdf' >{t('menu.auditReport')}</a>
                            </div>
                            : ''
                    }

                </div>
                <div className='copyright'>
                    {
                        !ism ? <div>© 2023 Galaxy Plan，All rights reserved.</div>
                            : ''
                    }
                    <div className='imgs'>
                        <a href='https://t.me/GalaxyPlanClub' target='_blank' rel="noreferrer"><img src={require('src/assets/images/footer2.png')} alt=''></img></a>
                        <a href='https://twitter.com/GalaxyPlanClub' target='_blank' rel="noreferrer"><img src={require('src/assets/images/footer1.png')} alt=''></img></a>
                        <a href="" target='_blank' rel="noreferrer"><img src={require('src/assets/images/footer3.png')} alt=''></img></a>
                        <a href='' target='_blank' rel="noreferrer"><img src={require('src/assets/images/footer4.png')} alt=''></img></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page;