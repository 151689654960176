import React, { useEffect, useState } from 'react';
import { Button, MenuProps } from 'antd';
import { Menu } from 'antd';
import logo from "../../assets/logo.png";
import './index.scss';
import { useAddress } from '../../hooks/useAddress';
import { isMobile } from '../../utils/pubilcUtils';
import ChangeLanguage from '../changeLanguage/index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccount, useConnect } from 'wagmi'
import pic from "../../assets/images/pic.png";
import pic1 from "../../assets/images/pic1.png";
import pic2 from "../../assets/images/pic2.png";
import pic3 from "../../assets/images/pic3.png";
import Trends from "./trends/trends.jsx"
interface WindowChain extends Window {
  ethereum?: any
}

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}


const App: React.FC = (props) => {
  const { t } = useTranslation();
  const address = useAddress();
  // const { address, isConnected, } = useAccount();
  const [name, setName] = useState('/');
  const navigator = useNavigate()
  const ism = isMobile();
  const [isTrends, setTrends] = useState(false);
  const router = () => {
    let name: any = window.location.pathname;
    if (name === '/') {
      setName('/');
    } else {
      setName(name.split('/')[1]);
    }
  };
  const customIcon = ism ? <img src={pic} /> : '';
  const customIcon1 = ism ? <img src={pic1} /> : '';
  const customIcon2 = ism ? <img src={pic2} /> : '';
  const customIcon3 = ism ? <img src={pic3} /> : '';
  const items: MenuProps['items'] = [
    // getItem(t('menu.home'), '/', ''),
    getItem(t('menu.home'), '/', customIcon),
    getItem(t('menu.about'), '/about', customIcon1, [
    ]),
    getItem(t('menu.News'), 'news', customIcon2),
    getItem(t('menu.auditReport'), 'report', customIcon3),

  ];

  useEffect(() => {
    router();
  }, [name]);

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'news') {
      setTrends(true);
    } else if (e.key === 'report') {
      window.open('https://galaxyplan.org/AuditReport.pdf', '_blank');
    } else {
      navigator(e.key)
    }
  };
  const openModal = () => {
    if ((window as WindowChain).ethereum) {
      (window as WindowChain).ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts: any) => {
          if (accounts[0]) {
            // console.log(accounts[0]);
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  }
  const go = () => { window.location.href = "/" }
  const openMenu = (e: any) => {
    let eleMenu = document.querySelectorAll('.mobile-menu');
    let eleDrawer = document.querySelectorAll('.drawer-mask');
    eleMenu[0].classList.add('show-menu');
    if (e) {
      eleMenu[0].classList.add('show-menu');
      eleDrawer[0].classList.add('drawer-show');
    } else {
      eleMenu[0].classList.remove('show-menu');
      eleDrawer[0].classList.remove('drawer-show');
    }
  };
  const handCancle = () => {
    setTrends(false);
  }
  return (

    !ism ? <><div className='main-header'>
      <div className='container'>
        <header>
          <div className='logo'>
            <img src={logo} onClick={go} alt=''></img>
          </div>
          <div className='right'>
            <Menu
              className='pc_menu'
              onClick={onClick}
              defaultSelectedKeys={[name]}
              defaultOpenKeys={[name]}
              selectedKeys={[name]}
              mode="horizontal"
              items={items} />

            <div className="hd_wallet">
              <>
                {address ? (
                  <div className="wallet_address">
                    <div>
                      {`${address.slice(0, 5)} ... ${address.slice(-5)}`}
                    </div>
                  </div>
                ) : (
                  <div className="ConnectWallet">
                    <Button onClick={openModal}>{t('ConnectWallet')}</Button>
                  </div>
                )}
              </>
              <ChangeLanguage></ChangeLanguage>

            </div>
          </div>
        </header>
      </div>
    </div>
      {
        isTrends ? <Trends onClose={handCancle}></Trends> : ''
      }
    </>
      :
      <><div className='mobile-header'>
        <header>
          <img src={logo} onClick={go} alt='' />
          <div className="hd_wallet">
            <>
              {address ? (
                <div className="wallet_address">
                  <div>
                    {`${address.slice(0, 5)} ... ${address.slice(-5)}`}
                  </div>
                </div>
              ) : (
                <div className="ConnectWallet">
                  <Button onClick={openModal}>{t('ConnectWallet')}</Button>
                </div>
              )}
            </>
          </div>
          <img
            className="mobile-menu-img"
            src={require('../../assets/menu.png')}
            onClick={openMenu.bind(this, true)}
            alt=''
          ></img>
        </header>
        <div className="mobile-menu">
          <div className='logo'>
            <img src={logo} alt="" />
          </div>
          <Menu
            className='pc_menu'
            onClick={onClick}
            defaultSelectedKeys={[name]}
            defaultOpenKeys={[name]}
            selectedKeys={[name]}
            mode="inline"
            items={items} />
          <ChangeLanguage></ChangeLanguage>
        </div>
        <div className="drawer-mask" onClick={openMenu.bind(this, false)}></div>
      </div> {
          isTrends ? <Trends onClose={handCancle}></Trends> : ''
        }</>
  );
};

export default App;