import { message } from "antd";


export function isMobile() {
  const userAgentInfo = navigator.userAgent;
  const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  for (let v = 0; v < mobileAgents.length; v += 1) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      return true;
    }
  }
  return false;
}
export function useIsPC() {
  const isPC = document.documentElement.offsetWidth > 1000
  return isPC
}

export function dateTimeWays(val:any) {
  let date = new Date(Number(val * 1000));
  let y = date.getFullYear();
  let m = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  let md = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  return y+'-'+m + '-' + d+' '+h+':'+md;
}

// 提示
export function messageWay(val:any, msg:any) {
  let styles = {
      marginTop: '28vh'
      // marginLeft: global.isMobile ? '240px' : ''
  };
  if (msg == 'success') {
      message.success({
          content: val,
          key: 'id_1', //可以通过唯一的 key 来更新loading的状态，否则会弹出两个框。
          duration: 2,
          style: styles
      });
  } else if (msg == 'error') {
      message.error({
          content: val,
          duration: 2,
          key: 'id_1',
          style: styles
      });
  } else if (msg == 'warning') {
      message.warning({
          content: val,
          duration: 2,
          key: 'id_1',
          style: styles
      });
  }
  else if (msg == 'loading') {
      const hide = message.loading({
          content: val,
          key: 'id_1',
          duration: 100,
          style: styles
      });
      setTimeout(hide, 2500);
  }
}