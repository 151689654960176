
import { Col, Modal, Row } from "antd"
import { dateTimeWays } from 'src/utils/pubilcUtils';
import { useEffect, useState } from "react";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import './index.scss';
import { t } from "i18next";
import useHomeData from "../hooks/useHomeData";
import { formatEther, formatUnits } from "@ethersproject/units";
const Page = (props: any) => {
    const { onClose } = props;
    const { recordsList } = useHomeData();
    const [isActive, setIsActive] = useState('Static');
    const [list, setList] = useState([{}, {}, {}]);
    const cancel = () => {
        onClose();
    }
    // useEffect(() => {
    //     console.log(recordsList);
    //     recordsList.sort((a: any, b: any) => b.time - a.time);
    //     console.log(recordsList);
    // }, [recordsList])

    const setType = (type: string) => {

    }

    const getName = (type: 'Static' | 'Levels' | 'Parent'): string => {
        const value = {
            Static: t('claimRecords.text'),
            Levels: t('claimRecords.text1'),
            Parent: t('claimRecords.text2'),
        }
        return value[type];
    }


    return <Modal
        className="claimRecord"
        open={true}
        footer={null}
        closable={true}
        onCancel={cancel}
        title={t('records.title')}
    >
        <div className='box'>
            <Row className="top">
                <Col span={8}>
                    <div>{t('records.text1')}</div>
                </Col>
                <Col span={8}>
                    <div>{t('records.text2')}(DOGE)</div>
                </Col>
                <Col span={8}>
                    <div>{t('records.text3')}</div>
                </Col>

            </Row>
            <div className="childrenList">
                <div className="lists">
                    {recordsList.map((item: any, key: number) => (
                        <Row key={key} className="item">
                            <Col span={8}>{getName(item.rewardType)}</Col>
                            <Col span={8}>
                                {parseFloat(String(formatUnits(String(item.amount), BigNumber.from(8))))}
                                {/* {parseFloat(formatEther(item.amount)).toFixed(2)} */}
                            </Col>
                            <Col span={8}>
                                {dateTimeWays(item.time)}
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        </div>
    </Modal>
}


export default Page;