import { useAddress } from "src/hooks/useAddress";
import { useChainId, useSendWeb3 } from "src/hooks/useWeb3";
import { useEffect, useState } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { messageWay } from "src/utils/pubilcUtils";
import GalaxyFamily from "src/abis/GalaxyFamily.json";
import LevelsContract from "src/abis/GalaxyLevels.json";
import GalaxyNodes from "src/abis/GalaxyNodes.json";
import CheckableTag from "antd/es/tag/CheckableTag";
import { useTranslation } from "react-i18next";

export default function useMyInfo() {
  const address = useAddress();
  const { t, i18n } = useTranslation();
  const sendWeb = useSendWeb3();
  const chainId = useChainId();
  const [isbind, setIsBind] = useState(Boolean);
  const [infoLoading, setLoading] = useState(false);
  const [isSindModule, setisSindModule] = useState(Boolean);
  const [inputParentAddress, setInputParentAddress] = useState("");
  const [superiorAddress, setsuperiorAddress] = useState("");
  const [childrenList, setChildrenList] = useState([]);
  const [rootAddress, setrootAddress] = useState([]);
  const [urlAddress, seturlAddress] = useState("");
  const [urllevelOf, seturlevelOf] = useState(Number);
  const [userearned, setearned] = useState(Number);
  const [usepower, setpower] = useState(Number);
  // const id = params.id;
  useEffect(() => {
    init();
    Refreshmy();
    Refreshbing();
  }, [sendWeb, address, isbind]);

  const Refreshmy = async () => {
    if (LevelsContract.address[chainId] == undefined) return;
    if (address) {
      const levels_contract = new sendWeb.eth.Contract(
        LevelsContract.abi,
        LevelsContract.address[chainId]
      );
      const Nodes_contract = new sendWeb.eth.Contract(
        GalaxyNodes.abi,
        GalaxyNodes.address[chainId]
      );
      let levelOf = await levels_contract.methods.levelOf(address).call();
      seturlevelOf(levelOf);

      //节点分红
      let earned = await Nodes_contract.methods.earned(address).call();
      let minerInfoOf = await Nodes_contract.methods
        .minerInfoOf(address)
        .call();
      setearned(earned);
      setpower(minerInfoOf.power);
    }
  };

  //绑定状态
  const Refreshbing = async () => {
    if (LevelsContract.address[chainId] == undefined) return;
    if (address) {
      const GalaxyFamily_contract = new sendWeb.eth.Contract(
        GalaxyFamily.abi,
        GalaxyFamily.address[chainId]
      );
      let parentOf = await GalaxyFamily_contract.methods
        .parentOf(address)
        .call();

      let rootAddress_ = await GalaxyFamily_contract.methods
        .rootAddress()
        .call();
      if (parentOf == "0x0000000000000000000000000000000000000000") {
        if (address === rootAddress_.toString()) {
          setIsBind(true);
        } else {
          setisSindModule(true);
          setIsBind(false);
        }
      } else {
        setIsBind(true);
      }

      setrootAddress(rootAddress_);
      setsuperiorAddress(parentOf);
    }
  };
  const Refreshbing1 = async () => {
    if (LevelsContract.address[chainId] == undefined) return;
    if (address) {
      const GalaxyFamily_contract = new sendWeb.eth.Contract(
        GalaxyFamily.abi,
        GalaxyFamily.address[chainId]
      );

      let parentOf = await GalaxyFamily_contract.methods
        .parentOf(address)
        .call();

      let rootAddress_ = await GalaxyFamily_contract.methods
        .rootAddress()
        .call();
      if (parentOf == "0x0000000000000000000000000000000000000000") {
        setIsBind(false);
      } else {
        setIsBind(true);
      }
      if (address === rootAddress_.toString()) {
        setIsBind(true);
      }
      setrootAddress(rootAddress_);
      setsuperiorAddress(parentOf);
    }
  };

  const init = async () => {
    if (LevelsContract.address[chainId] == undefined) return;
    if (address && typeof chainId === "number") {
      if (GalaxyFamily.address[chainId] == undefined) return;
      // const url = "https://GalaxyPlanProject.github.io";
      const url = window.location.href;
      const index = url.indexOf("?");
      const baseUrl = index === -1 ? url : url.slice(0, index);
      const newUrl = `${baseUrl}?#binAddres=${address}`;
      seturlAddress(newUrl);
      const levels_contract = new sendWeb.eth.Contract(
        LevelsContract.abi,
        LevelsContract.address[chainId]
      );

      let list = [];
      //下级
      let childrenOf = await levels_contract.methods
        .childrenAchievementsOf(address)
        .call();

      if (childrenOf) {
        for (let index = 0; index < childrenOf.length; index++) {
          const result = childrenOf[index];
          list.unshift({
            address: `${result.child.slice(0, 3)} ..... ${result.child.slice(
              -4
            )}`,
            pledge: await getStarrating(result.child),
            level: result.level,
          });
        }
        setChildrenList(list);
      }
    }
  };

  const getStarrating = async (ownaddress) => {
    const levels_contract = new sendWeb.eth.Contract(
      LevelsContract.abi,
      LevelsContract.address[chainId]
    );
    let resultstartOf = await levels_contract.methods
      .startOf(ownaddress)
      .call();
    return Number(resultstartOf);
  };

  // 节点领取
  const takeReward = async () => {
    setLoading(true);
    const Nodes_contract = new sendWeb.eth.Contract(
      GalaxyNodes.abi,
      GalaxyNodes.address[chainId]
    );
    try {
      await Nodes_contract.methods.takeReward().send({
        from: address,
      });
      Refreshmy();
      messageWay(t("home.tips.text4"), "success");
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const copyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    messageWay(t("tips.copyS"), "success");
  };

  const parentOfMethod = async (owner) => {
    const GalaxyFamily_contract = new sendWeb.eth.Contract(
      GalaxyFamily.abi,
      GalaxyFamily.address[chainId]
    );
    let parentOf = await GalaxyFamily_contract.methods.parentOf(owner).call();
    return parentOf;
  };

  const isAddress = async (owner) => {
    return sendWeb.utils.isAddress(owner);
  };
  //绑定
  const makeRelation = async () => {
    setLoading(true);
    const GalaxyFamily_contract = new sendWeb.eth.Contract(
      GalaxyFamily.abi,
      GalaxyFamily.address[chainId]
    );

    try {
      await GalaxyFamily_contract.methods
        .makeRelation(inputParentAddress)
        .send({
          from: address,
        });
      messageWay(t("home.tips.text2"), "success");
      setisSindModule(false);
      setIsBind(true);
    } catch (err) {
      console.log(err);
      setisSindModule(false);
    } finally {
      setLoading(false);
    }
  };

  return {
    address,
    isbind,
    infoLoading,
    inputParentAddress,
    superiorAddress,
    childrenList,
    rootAddress,
    urlAddress,
    urllevelOf,
    isSindModule,
    userearned,
    usepower,
    parentOfMethod,
    setInputParentAddress,
    makeRelation,
    isAddress,
    copyText,
    setisSindModule,
    init,
    setLoading,
    setIsBind,
    Refreshmy,
    takeReward,
    Refreshbing,
    Refreshbing1,
  };
}
