import { useContext, useMemo } from "react"
import { CoreDateContext } from "../providers/coreDateProvider"


export const useAddress = () => {

    const { address } = useContext(CoreDateContext)
    // const address = '0xA85a1482e161A8421f8034c29406D20F8b79c8E0'
    return useMemo(() => {
        return address
    }, [address])
}