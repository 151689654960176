import { useEffect, useMemo, useState } from "react";
import { useAddress } from "../../hooks/useAddress";
import { Button, Col, Descriptions, Input, Row, Tooltip } from "antd";
import { CalendarOutlined, CopyOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { formatUnits } from '@ethersproject/units';
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { messageWay } from "src/utils/pubilcUtils";
import leftTitle from 'src/assets/images/left_title.png';
import rightTitle from 'src/assets/images/right_title.png';
import sameImg from 'src/assets/images/same-qiu.png';
import './index.scss';
import './mobile.scss';
import { useIsPC } from '../../utils/pubilcUtils';
import EchartsLiquidfill from './hooks/useEchartsLiquidfill';
import ClaimRecords from './claimRecords/index';
import LevelModal from './level/level';
import BindingModal from './binding/binding';
import useHomeData from "./hooks/useHomeData";
import useMyInfo from "./hooks/useMyInfo";
import { useTranslation } from "react-i18next";
import { number } from "echarts";
const Page = () => {
    const { isNotYet, startOf, upgradeAmounts, balance, loading, submit, rewardQuota, rewardPending, claimLoading, claim, rewardTotal, Clicktoquery, clickable, setclickable, visibleUpgrade, setVisibleUpgrade, RefreshLevel, getInfo } = useHomeData();
    const { urllevelOf, urlAddress, copyText, isAddress, childrenList, superiorAddress, isbind, setInputParentAddress, inputParentAddress, infoLoading, makeRelation, parentOfMethod, address, rootAddress, setisSindModule, isSindModule, init, Refreshmy, userearned, takeReward, usepower, Refreshbing, Refreshbing1 } = useMyInfo();
    const [isActive, setIsActive] = useState(1);
    const [isVisible, setIsVisible] = useState(false);
    const IsPC = useIsPC();
    const { t } = useTranslation()


    // useEffect(() => {
    //     if (isbind == false) {
    //         setisSindModule(true);
    //     }
    // }, [isbind])

    const setType = (e: any) => {
        setIsActive(e);
    }
    const tab = (value: any) => {
        if (value === 'reduce') {
            if (isActive === 1) return;
            let result = isActive - 1;
            setIsActive(result);
        } else {
            if (isActive === 3) return;
            let result = isActive + 1;
            setIsActive(result);
        }
    }
    const handCancle = () => {
        setIsVisible(false);
        setVisibleUpgrade(false);
        setisSindModule(false);
    }
    const handOpen = () => {
        setIsVisible(true);
    }
    //等级图
    const getImagesForLevel = (startOf1: number) => {
        let imgs: string[] = [] as string[];
        switch (Number(startOf1)) {
            case 0:
                imgs = ["img1", "img1", "img1", "img1", "img1", "img1"];
                return imgs;
            case 1:
                imgs = ["img2", "img1", "img1", "img1", "img1", "img1"];
                return imgs
            case 2:
                imgs = ["img2", "img2", "img1", "img1", "img1", "img1"];
                return imgs;
            case 3:
                imgs = ["img2", "img2", "img2", "img1", "img1", "img1"];
                return imgs;
            case 4:
                imgs = ["img2", "img2", "img2", "img2", "img1", "img1"];
                return imgs;
            case 5:
                imgs = ["img2", "img2", "img2", "img2", "img2", "img1"];
                return imgs
            case 6:
                imgs = ["img2", "img2", "img2", "img2", "img2", "img2"];
                return imgs
            default:
                return [];
        }
    };
    const renderImages = (count: number) => {
        switch (Number(count)) {
            case 0:
                return
            case 1:
                return <img src={require('src/assets/images/other-dog.png')} alt="" />
            case 2:
                return <>
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                </>;
            case 3:
                return <>
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                </>;
            case 4:
                return <>
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                </>;
            case 5:
                return <>
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                </>;
            case 6:
                return <>
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                    <img src={require('src/assets/images/other-dog.png')} alt="" />
                </>;
        }
    }
    //等级视图
    const [newlisst, setnewlisst] = useState(Array);



    const handleClick = (index: number) => {
        let count = index + 1;
        let imgs = getImagesForLevel(Number(startOf));
        if (count > Number(startOf)) {
            for (let i = 0; i < imgs.length; i++) {
                imgs[index] = 'img3';
            }
            if (count > Number(startOf)) {
                if (count == Number(startOf) + 1) {
                    setclickable(false)
                } else {
                    setclickable(true)
                }
            }
            setnewlisst(imgs);
            Clicktoquery(index + 1);
        }

    };

    const imgripple = useMemo(() => {
        const renderedImages = [];
        let imgs: string[] = getImagesForLevel(Number(startOf));
        if (newlisst.length > 0) {
            imgs = newlisst as [];
        }

        for (let index = 0; index <= 6; index++) {
            if (imgs[index] == 'img1') {
                renderedImages.push(
                    <div className="same-img" key={index} onClick={() => handleClick(index)}>
                        <img className="imgripple" src={require('src/assets/images/default-dog.png')} alt=""></img>
                    </div>
                )
            }
            if (imgs[index] == 'img2') {
                renderedImages.push(
                    <div className="same-img" key={index} onClick={() => handleClick(index)}>
                        <img className="imgripple" src={require('src/assets/images/other-dog1.png')} alt=""></img>
                    </div>
                )
            }
            if (imgs[index] == 'img3') {
                renderedImages.push(
                    <div className="circle-container " key={index} onClick={() => handleClick(index)}>
                        <div className="circle-text ">
                            <div className="other-img"><img className="imgripple" src={require('src/assets/images/other-dog.png')} alt=""></img></div>
                        </div>
                        <div className="circle"></div>
                    </div>
                )
            }
        }
        return renderedImages
    }, [startOf, handleClick,]);

    //满星,未满
    const CupModal = useMemo(() => {

        if (Number(startOf) <= 5) {
            return <>
                <div className="next">{t('home.text6_1')} </div>
                <div className="amount"> {parseFloat(String(formatUnits(String(upgradeAmounts), BigNumber.from(8))))} DOGE</div>
            </>
        }
        if (Number(startOf) > 5) {
            return <div className="cup">
                <div className="cup-bg"> <img src={require('src/assets/images/cup.png')}></img></div>
                <div>{t('home.text6')}</div>
            </div>
        }

    }, [startOf, upgradeAmounts, t])



    //点亮按钮
    const upButton = useMemo(() => {
        if (Number(startOf) <= 5) {
            return <>
                <Button disabled={clickable} loading={loading} onClick={() => Onsubmit()} className="same-btn">{t('home.btn1')}</Button>
                <div className="balance">
                    <span>{t('Balance')} : </span>
                    <span className="amount"> {parseFloat(String(formatUnits(String(balance), BigNumber.from(8)))).toFixed(2)} DOGE</span>

                </div>
            </>
        }
        return '';
    }, [startOf, upgradeAmounts, loading, balance, clickable, isbind, isNotYet, t])

    const changeInput = (e: any) => {
        setInputParentAddress(e.target.value);

    }
    useEffect(() => {
        const urlset = () => {
            let hash = location.hash;
            let value = hash.split("=")[1];
            if (value != undefined) {
                if (superiorAddress.toString() === '0x0000000000000000000000000000000000000000' && address !== rootAddress.toString()) {
                    setInputParentAddress(value);
                    setIsActive(3);
                }
            }
        }
        urlset()
    }, [superiorAddress, rootAddress])

    //绑定
    useEffect(() => {
        if (isSindModule == false) {
            Refreshbing1();
        }

    }, [isSindModule])

    useEffect(() => {
        setnewlisst([])
        Refreshmy();
        getInfo();
        // if (visibleUpgrade == false) {
        //     RefreshLevel();
        // }
    }, [address, isbind, rewardPending, visibleUpgrade, userearned, rewardTotal])

    const bind = async () => {

        if (address) {
            const isAddress_ = await isAddress(inputParentAddress);

            if (inputParentAddress == '') {
                return messageWay(t('home.tips.text1'), 'error');
            } else if (inputParentAddress == address) {
                return messageWay(t('home.tips.text1'), 'error');
            } else if (!isAddress_) {
                return messageWay(t('home.tips.text1'), "error");
            }
            const parentOf = await parentOfMethod(inputParentAddress);
            if (inputParentAddress.toString() === rootAddress.toString()) {
                return makeRelation();
            }
            if (parentOf && parentOf.toString() === '0x0000000000000000000000000000000000000000') {
                return messageWay(t('home.tips.text1'), 'error');
            }
            makeRelation();
        } else {
            return messageWay(t('connectWallet'), 'error');
        }



    }
    const Copy = (e: any) => {
        copyText(e);
    }
    const Onsubmit = (() => {
        if (address) {
            if (isbind) {
                submit();
            } else {
                setisSindModule(true);
            }
        } else {
            return messageWay(t('connectWallet'), 'error');
        }

    })
    const takeRewards = (() => {
        takeReward();
    })

    // const a = ((e: any) => {
    //     return console.log(e);
    // })
    return (
        <div className="home" >
            <BindingModal onClose={handCancle} isSindModule={isSindModule} inputParentAddress={inputParentAddress}></BindingModal>
            <LevelModal onClose={handCancle} visibleUpgrade={visibleUpgrade}></LevelModal>
            {/* {
                visibleUpgrade && <LevelModal onClose={handCancle}></LevelModal>
            } */}
            {isVisible && <ClaimRecords onClose={handCancle}></ClaimRecords>}
            {
                IsPC ? <img style={{ width: '100%' }} src={require('src/assets/images/bg.png')} alt=""></img>
                    :
                    <img style={{ width: '100%', height: '400px' }} src={require('src/assets/images/mobilebg.png')} alt=""></img>
            }            <div className="content">

                {IsPC ?
                    <>
                        <div className="same-qiu">
                            <img src={sameImg}></img>
                        </div>
                        <div className="same-qiu qiu2">
                            <img src={sameImg}></img>
                        </div>
                    </>
                    : ''}
                <div className="title">{t('home.text1')}</div>
                <div className="subtitle">{t('home.text2')}</div>
                <div className="go">
                    {/* {
                        IsPC ?
                            <><Button> {t('home.text3') + '->'}</Button>
                                <div className="border1">
                                    <div className="border2">
                                        <img src={require('src/assets/images/radio.png')} alt=""></img>
                                    </div>
                                </div></>
                            :
                            <Button>{t('home.text3') + '->'}</Button>
                    }  */}
                    <Button> {t('home.text3') + '->'}</Button>
                    <div className="border1">
                        <div className="border2">
                            <img src={require('src/assets/images/radio.png')} alt=""></img>
                        </div>
                    </div>

                </div>
            </div>
            <div className="game">
                {
                    //旋转
                    IsPC && <div className="same-qiu qiu3">
                        <img src={sameImg}></img>
                    </div>
                }
                <div className="container">
                    <div className="same-title">
                        <img src={leftTitle} alt=""></img>
                        <div>{t('home.title1')}</div>
                        <img src={rightTitle} alt=""></img>
                    </div>

                    <div className="tabs">

                        <div className="tab">

                            <div className="tab-item">
                                <div className={isActive === 1 ? 'active' : ''} onClick={setType.bind(this, 1)}>{t('home.tab1')}</div>
                                <div className={isActive === 2 ? 'active' : ''} onClick={setType.bind(this, 2)}>{t('home.tab2')}</div>
                                <div className={isActive === 3 ? 'active' : ''} onClick={setType.bind(this, 3)}>{t('home.tab3')}</div>
                            </div>
                            {
                                IsPC ?
                                    <div className="tab-icon">
                                        <img src={require('src/assets/images/turn-left.png')} onClick={tab.bind(this, 'reduce')} alt=""></img>
                                        <img src={require('src/assets/images/turn-right.png')} onClick={tab.bind(this, 'add')} alt=""></img>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        <div className="tab-content" id={isActive === 1 ? 'isActive1' : ''}>
                            {
                                isActive === 1 ?
                                    <div className="block1">
                                        {
                                            IsPC ?
                                                <>
                                                    <div className="imgs">
                                                        {imgripple}
                                                    </div>
                                                    <div className="current">{t('home.text4')}
                                                        {
                                                            Number(startOf) > 0 ?
                                                                `X${Number(startOf)}` :
                                                                ''

                                                        }
                                                    </div>
                                                    <div className="same-line"></div>
                                                    {CupModal}
                                                </>
                                                :
                                                <>
                                                    {
                                                        Number(startOf) <= 5 ?
                                                            <div className="partake">
                                                                <div>
                                                                    <span>{t('home.text4')}</span>
                                                                    <div className="imgs">
                                                                        {imgripple}
                                                                    </div>
                                                                </div>
                                                                <div className="Illuminate">
                                                                    <div className="next">{t('home.text6_1')}</div>
                                                                    <div className="amount">{parseFloat(String(formatUnits(String(upgradeAmounts), BigNumber.from(8))))} DOGE</div>

                                                                </div>
                                                            </div> :
                                                            <div className="cup">
                                                                <img src={require('src/assets/images/cup.png')}></img>
                                                                <div>{t('home.text6')}</div>
                                                            </div>
                                                    }

                                                </>
                                        }

                                        {upButton}
                                    </div> :
                                    isActive === 2 ?
                                        <div className="block2">
                                            {
                                                IsPC ?
                                                    <div className="records" onClick={handOpen}>
                                                        <CalendarOutlined />
                                                        {t('records.title')}
                                                    </div>
                                                    :
                                                    ''
                                            }
                                            <div className="reward">
                                                <div className="box3">
                                                    <div className="border">
                                                        <div className="border1">
                                                            <EchartsLiquidfill rewardPendings={rewardPending}></EchartsLiquidfill>
                                                        </div>
                                                    </div>
                                                    <Button disabled={parseFloat(String(formatUnits(String(rewardPending), BigNumber.from(8)))) <= 0} loading={claimLoading} onClick={() => { claim() }} className="same-btn">{t('home.btn2')}</Button>
                                                </div>
                                                <div className="box2">
                                                    <div className="circle-container">
                                                        <div className="circle-text">{Number(startOf) >= 1 ? '1T' : '0'}</div>
                                                        <div className="circle"></div>
                                                    </div>
                                                    <div className="power">{t('home.text7')}</div>
                                                </div>
                                                <div className="box1">
                                                    <div className="circle-container">
                                                        <div className="circle-text">{parseFloat(String(formatUnits(String(rewardTotal), BigNumber.from(8))))}</div>
                                                        <div className="circle"></div>
                                                    </div>
                                                    <div className="content">
                                                        <span>{t('home.text8')}</span>
                                                        {
                                                            Number(startOf) > 2 ?
                                                                ''
                                                                :
                                                                <Tooltip className="reward-tooltip" title={
                                                                    <div className="tips">
                                                                        <div className="title">{t('home.text8_tips.title')}</div>
                                                                        <div className="content">{t('home.text8_tips.text')}</div>
                                                                    </div>
                                                                }>
                                                                    <ExclamationCircleFilled />
                                                                </Tooltip>
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                IsPC ?
                                                    ''
                                                    :
                                                    <div className="records" onClick={handOpen}>
                                                        <CalendarOutlined />
                                                        {t('records.title')}
                                                    </div>
                                            }

                                        </div> :
                                        <div className="block3">
                                            {
                                                isbind ?
                                                    <div className="bind">
                                                        <div className="top">
                                                            <div className="left">
                                                                <div className="myparent">{t('home.text10_1')}：{`${address?.slice(0, 5)} ..... ${address?.slice(-5)}`}
                                                                    <CopyOutlined onClick={Copy.bind(this, address)} />

                                                                </div>
                                                                <div className="invite-line">
                                                                    <div >{t('home.text10')}：</div>
                                                                    <div className="border">
                                                                        {`${urlAddress.slice(0, 15)} ..... ${urlAddress.slice(-5)}`}
                                                                        <CopyOutlined onClick={Copy.bind(this, urlAddress)} />
                                                                    </div>
                                                                    {/* <CopyToClipboard text={address} onCopy={Copy}>
                                                                        <CopyOutlined />
                                                                    </CopyToClipboard> */}

                                                                </div>

                                                            </div>
                                                            {
                                                                IsPC ?
                                                                    Number(usepower) >= 1 ?
                                                                        <div className="right">
                                                                            <div className="circle-container">
                                                                                <div className="circle-text1">
                                                                                    <span>{t('home.text15')}</span>
                                                                                    <span>{parseFloat(String(formatUnits(String(userearned), BigNumber.from(8)))).toFixed(2)}</span>
                                                                                </div>
                                                                                <div className="circle"></div>
                                                                            </div>
                                                                            <Button loading={infoLoading} disabled={parseFloat(String(formatUnits(String(userearned), BigNumber.from(8)))) <= 0} onClick={takeRewards}> {t('home.btn2')}</Button>
                                                                        </div> :
                                                                        ''
                                                                    :
                                                                    ''
                                                            }

                                                            <div className="right">
                                                                <div className="circle-container">
                                                                    <div className="circle-text">A{Number(urllevelOf)}</div>
                                                                    <div className="circle"></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            IsPC ?
                                                                ''
                                                                :
                                                                Number(usepower) >= 1 ? <div className="Nodedividend">
                                                                    <span>{t('home.text15')}</span>
                                                                    <span>{parseFloat(String(formatUnits(String(userearned), BigNumber.from(8)))).toFixed(2)}</span>
                                                                    <Button loading={infoLoading} disabled={parseFloat(String(formatUnits(String(userearned), BigNumber.from(8)))) <= 0} onClick={takeRewards}> {t('home.btn2')}</Button>
                                                                </div> : ''

                                                        }

                                                        <div className="same-line"></div>
                                                        <div className="table">
                                                            <Row className="top">
                                                                <Col span={8}>
                                                                    <div>{t('home.text12')}</div>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <div>{t('home.text13')}</div>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <div>{t('home.text14')}</div>
                                                                </Col>
                                                            </Row>
                                                            <div className="childrenList">
                                                                <div className="lists">
                                                                    {childrenList.map((item: any, key: number) => (
                                                                        <Row key={key} className="item">
                                                                            <Col span={8}>{item.address}</Col>
                                                                            <Col span={8}>
                                                                                {renderImages(item.pledge)}
                                                                            </Col>
                                                                            <Col span={8}>
                                                                                A{Number(item.level)}
                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="nobind">
                                                        <Descriptions column={1}>
                                                            <Descriptions.Item label={t('home.text9')}>
                                                                <Input onInput={changeInput} value={inputParentAddress} placeholder={t('home.text17')}></Input>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Button className="same-btn" loading={infoLoading} onClick={bind}>{t('home.btn3')}</Button>
                                                    </div>
                                            }

                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="friend">
                {IsPC && <div className="same-qiu">
                    <img src={sameImg}></img>
                </div>}
                <div className="container">
                    <div className="same-title">
                        <img src={leftTitle} alt=""></img>
                        <div>{t('home.text15')}</div>
                        <img src={rightTitle} alt=""></img>
                    </div>
                    {IsPC ?
                        <>
                            <div className="icons">
                                <img src={require('src/assets/images/icon1.png')} alt=""></img>
                                <img src={require('src/assets/images/icon2.png')} alt=""></img>
                                <img src={require('src/assets/images/icon3.png')} alt=""></img>

                            </div>
                            <div className="icons">
                                <img src={require('src/assets/images/icon4.png')} alt=""></img>
                                <img src={require('src/assets/images/icon5.png')} alt=""></img>
                            </div>
                        </> :
                        <div className="icons">
                            <img src={require('src/assets/images/icon1.png')} alt=""></img>
                            <img src={require('src/assets/images/icon2.png')} alt=""></img>
                            <img src={require('src/assets/images/icon3.png')} alt=""></img>
                            <img src={require('src/assets/images/icon4.png')} alt=""></img>
                            <img src={require('src/assets/images/icon5.png')} alt=""></img>

                        </div>
                    }
                </div>
            </div>

        </div >
    )
}

export default Page;