import React from 'react';
import {  Layout } from 'antd';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import { createHashHistory, History } from 'history';
import routerConfig from './routeConfig';
import HOME from './pages/home/index';
import Header from './components/header/index';
import Footer from './components/footer/index';
import { CoreDateProvider } from './providers/coreDateProvider';

const App = () => {

  return (
    <CoreDateProvider>
      <Router>
        <Layout>
          <Header></Header>
          <Routes>
            {routerConfig}
            <Route path="/" element={<HOME />}></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
           <Footer></Footer>
        </Layout>
      </Router>
    </CoreDateProvider>

  );
};

export default App;