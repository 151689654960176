import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const Index = lazy(() => import('./pages/home/index'));

const help = lazy(() => import('./pages/hepl/index'));

const text = lazy(() => import('./pages/hepl/index'));


const routes = [
    {
        // 首页
        path: '/',
        component: Index
    },
    {
        // 好友
        path: '/help',
        component: help
    },
    {
        path: '/text',
        component: text
    }
];

const rootConfig = routes.map(({ path, component: Component }) => (
    <Route key={path} path={path} element={<Component />} />
));

export default rootConfig;